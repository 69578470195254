import { ValuesPerEnvironment, Environment, extractConfigValuesBasedOnEnvironment } from './configEnvironment';

export interface Config {
  readonly amplitudeExperimentApiKey: string;
  readonly amplitudeExperimentFlagsServer: string;
  readonly amplitudeExperimentServer: string;
  readonly environment: Environment;
}

/**
 * Keep all marcom static config at one place
 * @todo Move all config here.
 */
const config: ValuesPerEnvironment<Config> = {
  amplitudeExperimentApiKey: {
    development: 'client-VBfZGm3gUxArOnypZ2EvbaibP8F9niEk',
    production: 'client-duj6ZiTMbFQqhVN4EDm9OgNyCrWtMqU0',
  },
  amplitudeExperimentFlagsServer: 'https://p.withjoy.com/ampflags',
  amplitudeExperimentServer: 'https://p.withjoy.com/ampexp',
  environment: {
    development: 'development',
    production: 'production',
  },
};

let builtConfig: Config;
export function getConfig(): Config {
  if (!builtConfig) {
    builtConfig = extractConfigValuesBasedOnEnvironment(config);
  }

  return builtConfig;
}
