const React = require('react');
const { MarcomThemeProvider } = require('./src/components/MarcomThemeProvider');
const { initSegment, initAmplitudeExperiment } = require('./static/js/initialScripts');
const { storeCurrentAcquisitionContext } = require('./src/gatsby-browser/acquisition-context-tracking');
const { GraphQLClientProvider } = require('./src/utils/graphql/GraphQLClientProvider');
const { BLISS_GATEWAY_ROOT_URL } = require('./src/utils/urlHelpers.util');

/**
 * Implement Gatsby's Browser APIs in this file.
 **/
require('intersection-observer');
const { segment } = require('./static/js/joy');
const { initFallback } = require('./src/utils/imageFallback');

initFallback(segment);

let isInitialized = false;
exports.onInitialClientRender = () => {
  const global = window;
  if ((global.location.search || '').replace('feature.marcom=', '').includes('feature.')) {
    // only load the query string parsing library
    // if there is a query string in the url
    require(['./src/gatsby-browser/query-string-features'], qs => {
      qs.init();
    });
  }
  if ('scrollRestoration' in history) {
    history.scrollRestoration = 'manual';
  }
  global.scrollTo(0, 0);
  setTimeout(() => {
    isInitialized = true;
  }, 100);
};

exports.shouldUpdateScroll = ({ routerProps: { location }, getSavedScrollPosition }) => {
  return isInitialized;
};

exports.onClientEntry = () => {
  if (global) {
    initSegment();
    segment.initTelemetry();
    segment.setDestinationHostname();
    initAmplitudeExperiment();
  }
};

exports.wrapRootElement = ({ element }) => (
  <GraphQLClientProvider endpoint={`${BLISS_GATEWAY_ROOT_URL}/graphql`}>
    <MarcomThemeProvider>{element}</MarcomThemeProvider>
  </GraphQLClientProvider>
);

exports.onRouteUpdate = () => {
  // Runs on initial load + after subsequent route updates
  storeCurrentAcquisitionContext();
};
