import { addAction } from '@utils/logger';
import { getLocalStorage } from '@utils/storage';

import { ACQUISITION_CONTEXTS_KEY, MAX_ACQUISITION_CONTEXTS } from './constants';
import { AcquisitionContext, AcquisitionContextData } from './types';

function logAcquisitionContextTrackingEvent(type: 'error' | 'success', data: Record<string, unknown>): void {
  addAction('AcquisitionContextTracking', { ...data, type });
}

export function readAcquisitionContexts(): AcquisitionContext[] {
  const storage = getLocalStorage();
  let acquisitionContexts: AcquisitionContext[] = [];
  let error: unknown;

  try {
    const acquisitionContextsStr = storage.getItem(ACQUISITION_CONTEXTS_KEY);
    if (acquisitionContextsStr) {
      const maybeAcquisitionContexts = JSON.parse(acquisitionContextsStr);
      if (Array.isArray(maybeAcquisitionContexts)) {
        acquisitionContexts = maybeAcquisitionContexts;
      } else {
        error = 'acquisitionContexts is not an array | Attempting to parse: ' + acquisitionContextsStr;
      }
    }
  } catch (e) {
    error = e;
  }

  if (error) {
    logAcquisitionContextTrackingEvent('error', { error });
  }

  return acquisitionContexts;
}

function writeAcquisitionContexts(acquisitionContexts: AcquisitionContext[]): void {
  const storage = getLocalStorage();
  let error: unknown;

  try {
    storage.setItem(ACQUISITION_CONTEXTS_KEY, JSON.stringify(acquisitionContexts));
  } catch (e) {
    error = e;
  }

  if (error) {
    logAcquisitionContextTrackingEvent('error', { error, acquisitionContexts, storageType: storage.type });
  } else {
    logAcquisitionContextTrackingEvent('success', {
      acquisitionContexts,
      acquisitionContextsLength: acquisitionContexts.length,
      storageType: storage.type,
    });
  }
}

export function storeCurrentAcquisitionContext(locationParam = window.location, referrer = document.referrer): void {
  const acquisitionContextData: AcquisitionContextData[] = [];
  const searchParams = new URLSearchParams(locationParam.search);
  searchParams.forEach((value, key) => {
    if (key && value) {
      acquisitionContextData.push({ key, value });
    }
  });

  const acquisitionContext: AcquisitionContext = {
    timestamp: Date.now(),
    relativeUrl: locationParam.pathname,
    referrer,
    data: acquisitionContextData,
  };

  const newAcquisitionContexts = readAcquisitionContexts().concat(acquisitionContext);
  if (newAcquisitionContexts.length > MAX_ACQUISITION_CONTEXTS) {
    newAcquisitionContexts.shift();
  }

  writeAcquisitionContexts(newAcquisitionContexts);
}
