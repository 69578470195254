import { defaultColors, defaultLinks, defaultTypography } from './default';
import { Breakpoints, MaxColumnsWidths, MediaQueries, Theme } from './types';
import deepMerge from 'deepmerge';
import { createSpaceScale } from './createSpaceScale.util';

function overwriteMerge(destinationArray: object[], sourceArray: object[]) {
  return sourceArray;
}

export const xsToSmBreakpointPx = 834;
export const tabletBreakpointPx = 768;

const joykitIconSize = [16, 24, 32, 40, 48, 64] as const;
/**
 * Joykit icons meant to be square
 */
export type JoykitIconSize = ElementType<typeof joykitIconSize>;

export function createTheme(rootFontSize: number = 16, overrides?: Theme): Theme {
  const space = createSpaceScale(rootFontSize);

  const maxColumnsWidths: MaxColumnsWidths = {
    below_sm: 688,
    tablet: 688,
    sm: 688,
    md: 1032,
    lg: 1224,
    xl: 1224,
  };

  const breakpoints: Breakpoints = [
    `${xsToSmBreakpointPx / rootFontSize}rem`, // 48.0625rem
    `${1152 / rootFontSize}rem`, // 72rem
    `${1440 / rootFontSize}rem`, // 90rem
    `${1800 / rootFontSize}rem`, // 112.5rem
  ];

  const mediaQueries: MediaQueries = {
    below_sm: `@media screen and (max-width: ${(xsToSmBreakpointPx - 1) / rootFontSize}rem)`,
    // tablet breakpoint Should be rarely used with Design Team confirmation and used to override mobile styles for tablet screen.
    tablet: `@media (min-width: ${(tabletBreakpointPx - 1) / rootFontSize}rem) and (max-width: ${
      (xsToSmBreakpointPx - 1) / rootFontSize
    }rem)`,
    sm: `@media screen and (min-width: ${breakpoints[0]})`,
    md: `@media screen and (min-width: ${breakpoints[1]})`,
    lg: `@media screen and (min-width: ${breakpoints[2]})`,
    xl: `@media screen and (min-width: ${breakpoints[3]})`,
  };

  const theme: Theme = {
    global: {
      rootFontSize,
    },
    space,
    breakpoints,
    maxColumnsWidths,
    mediaQueries,
    colors: defaultColors,
    typography: defaultTypography,
    direction: 'ltr',
    links: defaultLinks,
    joykitIconSize,
  };
  return deepMerge<Theme>(theme, overrides || {}, {
    arrayMerge: overwriteMerge,
  });
}
