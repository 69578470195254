interface Segment {
  sendTelemetryEvent: (eventName: string, eventPayload: object) => void;
}

export const initFallback = (segment: Segment) => {
  if (typeof window !== 'object') {
    return () => {};
  }

  const primaryImagePrefix = 'https://withjoy.com/assets/public/';
  const fallbackImagePrefix = 'https://d2uft7zh7kxc3y.cloudfront.net/';

  /* replace with the below once the nginx routing is in place
  const primaryImagePrefix = 'https://withjoy.com/assets/';
  const fallbackImagePrefix = 'https://withjoy.com/assets-fallback/';
  */

  const replaceImgSrcHandler = (ev: ErrorEvent) => {
    const img = ev.target as HTMLImageElement;
    const prevSrc = img?.src;
    let sendTelemetry = false; // bing beacons and other trackers result in errors so only log events we care about
    if (prevSrc && img.tagName === 'IMG') {
      if (prevSrc.startsWith(primaryImagePrefix)) {
        img.src = fallbackImagePrefix + prevSrc.substr(primaryImagePrefix.length);
        sendTelemetry = true;
      } else if (prevSrc.startsWith(fallbackImagePrefix)) {
        sendTelemetry = true;
      }
    }

    if (sendTelemetry) {
      segment.sendTelemetryEvent('ResourceLoadingError', {
        error: ev.error,
        message: ev.message,
        src: prevSrc,
      });
    }
  };

  window.addEventListener('error', replaceImgSrcHandler, true);

  return () => {
    window.removeEventListener('error', replaceImgSrcHandler, true);
  };
};
