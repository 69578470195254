import { Colors, LinkVariantsSet, TypographyTextStyle, TypographyVariantSet } from './';
import { createTheme } from './createTheme.util';

const actionPurple9 = 'rgba(250,247,255,1)';
const actionPurple8 = 'rgba(238,235,255,1)';
const actionPurple7 = 'rgba(217,217,255,1)';
const actionPurple4 = 'rgba(113,57,191,1)';
const actionPurple3 = 'rgb(95,38,155,1)';
const actionPurple2 = 'rgb(80,32,128,1)';
const actionPurple1 = 'rgba(68,0,102,1)';
const mono7 = 'rgba(255,255, 255, 1)';
const black = 'rgba(0, 0, 0, 1)';
const purple4 = 'rgba(95,38,152,1)';

// Some colors differ from default joykit
const linkFooterDefault = 'rgba(131,108,160,1)';
const linkFooterHover = 'rgba(193,161,255,1)';
const linkHomeFeature = 'rgba(188,129,41,1)';
const linkHomeFeatureHover = 'rgba(67,33,33,1)';
//

// export const defaultSpacing: Spacing = {
//   xxxs: '6px',
//   xxs: '8px',
//   xs: '12px',
//   sm: '16px',
//   md: '20px',
//   lg: '24px',
//   xl: '28px',
//   xxl: '32px',
//   xxxl: '40px'
// }

export const defaultColors: Colors = {
  purple1: 'rgba(251,247,255,1)',
  purple2: 'rgba(241,235,255,1)',
  purple3: 'rgba(229,217,255,1)',
  purple4,
  purple5: 'rgba(51,0,102,1)',
  purple6: 'rgba(32,0,65,1)',
  newPurple5: '#3D007A',
  blue1: 'rgba(247,252,255,1)',
  blue2: 'rgba(235,247,255,1)',
  blue3: 'rgba(211,231,255,1)',
  blue4: 'rgba(122,152,255,1)',
  blue5: 'rgba(79,107,203,1)',
  blue6: 'rgba(0,8,76,1)',
  green1: 'rgba(248,255,247,1)',
  green2: 'rgba(235,255,232,1)',
  green3: 'rgba(201,250,212,1)',
  green4: 'rgba(0,146,137,1)',
  green5: 'rgba(23,111,105,1)',
  green6: 'rgba(0,39,27,1)',
  yellow1: 'rgba(255, 252, 247, 1)',
  yellow1A70: 'rgba(255, 252, 247, 0.7)',
  yellow2: 'rgba(255,245,229,1)',
  yellow3: 'rgba(255,237,209,1)',
  yellow4: 'rgba(255,160,13,1)',
  yellow5: 'rgba(188,129,41,1)',
  yellow6: 'rgba(80,50,2,1)',
  red1: 'rgba(255,248,247,1)',
  red2: 'rgba(255,236,235,1)',
  red3: 'rgba(255,215,212,1)',
  red4: 'rgba(255,100,89,1)',
  red5: 'rgba(178,54,74,1)',
  red6: 'rgba(102,0,55,1)',
  yellowShade3: 'rgba(242, 195, 184, 0.6)',
  actionPurple9,
  actionPurple8,
  actionPurple7,
  actionPurple4,
  actionPurple3,
  actionPurple2,
  actionPurple1,
  mono7,
  black,
  mono6: 'rgba(251,251,251,1)',
  mono5: 'rgba(246,246,246,1)',
  mono4: 'rgb(235,235,235,1)',
  mono3: 'rgb(214,214,214,1)',
  mono2Fitty: 'rgba(190,190,190,1)',
  mono2: 'rgba(125,125,125,1)',
  mono1: 'rgba(51,51,51,1)',
  mono12: 'rgba(89,89,89,1)',
  linkDefault: actionPurple3,
  linkHover: actionPurple4,
  linkPress: actionPurple2,
  linkFooterDefault,
  linkFooterHover,
  linkFooterPress: purple4,
  linkHomeFeature: linkHomeFeature,
  linkHomeFeatureHover: linkHomeFeatureHover,
  ctaDefault: actionPurple2,
  ctaHover: actionPurple3,
  ctaPress: actionPurple1,
  secondaryCtaDefault: mono7,
  secondaryCtaHover: actionPurple9,
  secondaryCtaPress: actionPurple8,
  covidCalculatorLowRiskIndicator: 'rgba(0,146,137,1)',
  covidCalculatorMediumRiskIndicator: 'rgba(255,214,0, 1)',
  covidCalculatorHighRiskIndicator: 'rgba(255,138,0, 1)',
  humana1: 'rgba(255,243,232,1)',
  humana2: 'rgba(246,217,196,1)',
  humana3: 'rgba(230,182,155,1)',
  humana4: 'rgba(167,118,97,1)',
  humanaDark4: 'rgba(149,97,73,1)',
  humanaDark6: 'rgba(67,33,33,1)',
  humana5: 'rgba(89,50,42,1)',
  humana6: 'rgba(67,33,33,1)',
  neutra1: 'rgba(249,245,240,1)',
  neutra2: 'rgba(220,212,201,1)',
  neutra3: 'rgba(187,177,163,1)',
  neutra4: 'rgba(128,117,102,1)',
  neutra5: 'rgba(90,84,74,1)',
  neutra6: 'rgba(44,41,37,1)',
  link: '#5F269B',
  white: '#ffffff',
  footerText: 'rgba(193,161,255,1)',
  navbar: 'rgba(255,255,255,0.72)',
  primeTextBlue: 'rgba(51,0,102,1)',
  primeHoverBlue: 'rgba(95,38,152,1)',
  primeActiveBlue: 'rgba(249,249,249,0.2)',
  announcementPurple: 'rgba(51,0,102,0.8)',
  warmGray1: 'rgba(215,210,203, 1)',
  accentBlue5: '#1d207a',
  accentBlue4: 'rgba(73,77,203,1)',
  humana1Light: '#fff9f5',
  accentBlue6: '#00084c',
  accentBlue1: '#F7FCFF',
  humana2Light: 'rgba(255, 228, 208, 1)',
  blackDarkTheme: 'rgba(33, 28, 33, 1)',
};

const Hed120SuperEmphasis: TypographyTextStyle = {
  fontFamily: 'Austin News Headline Web',
  fontStyle: 'italic',
  fontWeight: 'normal',
  fontSize: '120px',
  lineHeight: '136px',
  letterSpacing: '-0.03em',
};

const Hed120Super: TypographyTextStyle = {
  fontFamily: 'Austin News Headline Web',
  fontStyle: 'normal',
  fontWeight: 300,
  fontSize: '120px',
  lineHeight: '136px',
  letterSpacing: '-0.01em',
};

const Hed96Break: TypographyTextStyle = {
  fontFamily: 'Austin News Headline Web',
  fontStyle: 'normal',
  fontWeight: 300,
  fontSize: '96px',
  lineHeight: '114px',
  letterSpacing: '-0.005em',
};

const Hed72Hero: TypographyTextStyle = {
  fontFamily: 'Austin News Headline Web',
  fontStyle: 'normal',
  fontWeight: 300,
  fontSize: '72px',
  lineHeight: '88px',
  letterSpacing: '-0.015em',
};

const Hed56: TypographyTextStyle = {
  fontFamily: 'Austin News Headline Web',
  fontStyle: 'normal',
  fontWeight: 300,
  fontSize: '56px',
  lineHeight: '68px',
};

const Hed40: TypographyTextStyle = {
  fontFamily: 'Austin News Deck Web',
  fontStyle: 'normal',
  fontWeight: 300,
  fontSize: '40px',
  lineHeight: '48px',
};

const Hed32: TypographyTextStyle = {
  fontFamily: 'Austin News Deck Web',
  fontStyle: 'normal',
  fontWeight: 300,
  fontSize: '32px',
  lineHeight: '38px',
};

const CalloutHed40Bold: TypographyTextStyle = {
  fontFamily: 'AkkuratLLSub-BoldSubset, sans-serif',
  fontSize: '40px',
  lineHeight: '54px',
  letterSpacing: '-0.015em',
};

const CalloutHed48: TypographyTextStyle = {
  fontFamily: 'AkkuratLLSub-RegularSubset, sans-serif',
  fontSize: '48px',
  lineHeight: '64px',
  letterSpacing: '-0.015em',
};

const CalloutHed40: TypographyTextStyle = {
  fontFamily: 'AkkuratLLSub-RegularSubset, sans-serif',
  fontSize: '40px',
  lineHeight: '54px',
  letterSpacing: '-0.015em',
};

const CalloutHed24: TypographyTextStyle = {
  fontFamily: 'AkkuratLLSub-RegularSubset, sans-serif',
  fontSize: '24px',
  lineHeight: '29px',
  letterSpacing: '-0.015em',
};

const CalloutHed32: TypographyTextStyle = {
  fontFamily: 'AkkuratLLSub-BoldSubset, sans-serif',
  fontSize: '32px',
  lineHeight: '42px',
};

const CalloutDek40: TypographyTextStyle = {
  fontFamily: 'AkkuratLLSub-LightSubset, sans-serif',
  fontSize: '40px',
  lineHeight: '55px',
  letterSpacing: '-0.02em',
};

const CalloutDek32: TypographyTextStyle = {
  fontFamily: 'AkkuratLLSub-RegularSubset, sans-serif',
  fontSize: '32px',
  lineHeight: '42px',
  letterSpacing: '-0.01em',
};

const SectionEyebrow24: TypographyTextStyle = {
  fontFamily: 'AkkuratLLSub-BoldSubset, sans-serif',
  fontSize: '24px',
  lineHeight: '36px',
  letterSpacing: '0.005em',
};

const SectionEyebrow20: TypographyTextStyle = {
  fontFamily: 'AkkuratLLSub-BoldSubset, sans-serif',
  fontSize: '20px',
  lineHeight: '32px',
};

const AustinNewsDek24: TypographyTextStyle = {
  fontFamily: 'Austin News Deck Web',
  fontSize: '24px',
  lineHeight: '29px',
  letterSpacing: '0.01em',
  fontWeight: 'normal',
};

const Dek24: TypographyTextStyle = {
  fontFamily: 'AkkuratLLSub-RegularSubset, sans-serif',
  fontSize: '24px',
  lineHeight: '36px',
  letterSpacing: '-0.005em',
};

const Dek20: TypographyTextStyle = {
  fontFamily: 'AkkuratLLSub-RegularSubset, sans-serif',
  fontSize: '20px',
  lineHeight: '32px',
};

const FeatureHed17: TypographyTextStyle = {
  fontFamily: 'AkkuratLLSub-BoldSubset, sans-serif',
  fontSize: '17px',
  lineHeight: '26px',
  letterSpacing: '0.01em',
};

const FeatureHed20: TypographyTextStyle = {
  fontFamily: 'AkkuratLLSub-BoldSubset, sans-serif',
  fontSize: '20px',
  lineHeight: '32px',
};

const FeatureCopy17: TypographyTextStyle = {
  fontFamily: 'AkkuratLLSub-RegularSubset, sans-serif',
  fontSize: '17px',
  lineHeight: '27px',
};

const Caption: TypographyTextStyle = {
  fontFamily: 'AkkuratLLSub-RegularSubset, sans-serif',
  fontSize: '12px',
  lineHeight: '17px',
};

const Legal: TypographyTextStyle = {
  fontFamily: 'AkkuratLLSub-RegularSubset, sans-serif',
  fontSize: '11px',
  lineHeight: '14px',
};

const Stat: TypographyTextStyle = {
  fontFamily: 'Austin News Headline Web',
  fontStyle: 'normal',
  fontWeight: 300,
  fontSize: '72px',
  lineHeight: '108px',
  letterSpacing: '0.01em',
};

const Hed96SuperEmphasis: TypographyTextStyle = {
  fontFamily: 'Austin News Headline Web',
  fontStyle: 'italic',
  fontWeight: 'normal',
  fontSize: '96px',
  lineHeight: '92px',
  letterSpacing: '-0.005em',
};

const Hed56SuperEmphasis: TypographyTextStyle = {
  fontFamily: 'Austin News Headline Web',
  fontStyle: 'italic',
  fontWeight: 'normal',
  fontSize: '56px',
  lineHeight: '62px',
  letterSpacing: '-0.005em',
};

const Hed32Mobile: TypographyTextStyle = {
  fontFamily: 'Austin News Deck Web',
  fontStyle: 'normal',
  fontWeight: 300,
  fontSize: '32px',
  lineHeight: '38px',
};

const Dek32: TypographyTextStyle = {
  fontFamily: 'AkkuratLLSub-RegularSubset, sans-serif',
  fontSize: '32px',
  lineHeight: '42px',
  letterSpacing: '0.005em',
};

const Dek40: TypographyTextStyle = {
  fontFamily: 'AkkuratLLSub-RegularSubset, sans-serif',
  fontSize: '40px',
  lineHeight: '50px',
  letterSpacing: '-0.01em',
};

const Dek15: TypographyTextStyle = {
  fontFamily: 'AkkuratLLSub-RegularSubset, sans-serif',
  fontSize: '15px',
  lineHeight: '22px',
  fontWeight: 400,
  fontStyle: 'normal',
};

const NavParent18: TypographyTextStyle = {
  fontFamily: 'AkkuratLLSub-BoldSubset, sans-serif',
  fontSize: '18px',
  lineHeight: '26px',
  letterSpacing: '0.01em',
};

const NavParent15: TypographyTextStyle = {
  fontFamily: 'AkkuratLLSub-BoldSubset, sans-serif',
  fontSize: '15px',
  lineHeight: '13px',
  letterSpacing: '0.01em',
};

const NavChild32: TypographyTextStyle = {
  fontFamily: 'Austin News Deck Web',
  fontStyle: 'normal',
  fontWeight: 300,
  fontSize: '32px',
  lineHeight: '64px',
};

const NavChild36: TypographyTextStyle = {
  fontFamily: 'Austin News Deck Web',
  fontStyle: 'normal',
  fontWeight: 300,
  fontSize: '36px',
  lineHeight: '64px',
  letterSpacing: '-0.005em',
};

const NavSearch48: TypographyTextStyle = {
  fontFamily: 'AkkuratLLSub-BoldSubset, sans-serif',
  fontSize: '48px',
  lineHeight: '57px',
  letterSpacing: '-0.015em',
};

const NavSearch122: TypographyTextStyle = {
  fontFamily: 'AkkuratLLSub-BoldSubset, sans-serif',
  fontSize: '122px',
  lineHeight: '201px',
  letterSpacing: '-0.015em',
};

const NavSearch148: TypographyTextStyle = {
  fontFamily: 'AkkuratLLSub-BoldSubset, sans-serif',
  fontSize: '172px',
  lineHeight: '201px',
  letterSpacing: '-0.015em',
};

export const defaultTypography: TypographyVariantSet = {
  hed120SuperEmphasis: Hed120SuperEmphasis,
  hed120Super: Hed120Super,
  hed96Break: Hed96Break,
  hed72Hero: Hed72Hero,
  hed56: Hed56,
  hed40: Hed40,
  hed32: Hed32,
  calloutHed40Bold: CalloutHed40Bold,
  calloutHed48: CalloutHed48,
  calloutHed40: CalloutHed40,
  calloutHed32: CalloutHed32,
  calloutHed24: CalloutHed24,
  calloutDek40: CalloutDek40,
  calloutDek32: CalloutDek32,
  sectionEyeBrow24: SectionEyebrow24,
  sectionEyeBrow20: SectionEyebrow20,
  dek24: Dek24,
  austinNewsDek24: AustinNewsDek24,
  dek20: Dek20,
  dek15: Dek15,
  featureHed17: FeatureHed17,
  featureHed20: FeatureHed20,
  featureCopy17: FeatureCopy17,
  caption: Caption,
  legal: Legal,
  stat: Stat,
  hed96SuperEmphasis: Hed96SuperEmphasis,
  hed56SuperEmphasis: Hed56SuperEmphasis,
  hed32Mobile: Hed32Mobile,
  dek32: Dek32,
  dek40: Dek40,
  navParent18: NavParent18,
  navParent15: NavParent15,
  navChild32: NavChild32,
  navChild36: NavChild36,
  navSearch48: NavSearch48,
  navSearch122: NavSearch122,
  navSearch148: NavSearch148,
};

export const defaultLinks: LinkVariantsSet = {
  primary: {
    color: 'linkDefault',
    '&:hover': {
      color: 'linkHover',
    },
    '&:active': {
      color: 'linkPress',
    },
  },
  altPurple: {
    color: 'mono7',
    '&:hover': {
      color: 'purple3',
    },
    '&:active': {
      color: 'purple4',
    },
  },
  altBlue: {
    color: 'mono7',
    '&:hover': {
      color: 'blue3',
    },
    '&:active': {
      color: 'blue5',
    },
  },
  altGreen: {
    color: 'mono7',
    '&:hover': {
      color: 'green3',
    },
    '&:active': {
      color: 'green5',
    },
  },
  altRed: {
    color: 'mono7',
    '&:hover': {
      color: 'red3',
    },
    '&:active': {
      color: 'red5',
    },
  },
  alternative: {
    color: 'mono7',
    '&:hover': {
      color: 'actionPurple9',
    },
    '&:active': {
      color: 'actionPurple8',
    },
  },
  footer: {
    color: 'linkFooterDefault',
    '&:hover': {
      color: 'linkFooterHover',
    },
    '&:active': {
      color: 'purple4',
    },
  },
  footerAlt: {
    color: 'footerText',
    '&:hover': {
      color: 'purple3',
    },
    '&:active': {
      color: 'purple4',
    },
  },
  footerAltDefault: {
    color: 'mono1',
    '&:hover': {
      color: 'mono12',
    },
    '&:active': {
      color: 'mono1',
    },
  },
  homeFeature: {
    color: 'linkHomeFeature',
    '&:hover': {
      color: 'linkHomeFeatureHover',
    },
    '&:active': {
      color: 'linkHomeFeatureHover',
    },
  },
  humanaLink: {
    color: 'humana1',
    '&:hover': {
      color: 'purple3',
    },
    '&:active': {
      color: 'purple4',
    },
  },
  redLink: {
    color: 'red5',
    '&:hover': {
      color: 'red3',
    },
    '&:active': {
      color: 'red2',
    },
  },
  yellowLink: {
    color: 'yellow1',
    '&:hover': {
      color: 'purple3',
    },
    '&:active': {
      color: 'purple4',
    },
  },
  navLink: {
    color: 'neutra6',
    '&:hover': {
      color: 'yellow5',
    },
    '&:active': {
      color: 'yellow4',
    },
  },
  neutraLink: {
    color: 'neutra4',
    '&:hover': {
      color: 'neutra5',
    },
    '&:active': {
      color: 'neutra6',
    },
  },
  humanaDarkLink: {
    color: 'humana4',
    '&:hover': {
      color: 'humana5',
    },
    '&:active': {
      color: 'humana6',
    },
  },
};

export const defaultTheme = createTheme(16);
