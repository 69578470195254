import { addAction } from '@utils/logger';
import { capitalize } from '@utils/strings.util';
import { withWindow } from '@utils/withWindow';
import { TelemetryBag, SegmentOptions, Integrations, IdentifyTraits, JoyEventType, PageViewArgs } from './types';

let joyWsid: string | null,
  destHostname: string,
  telemetryBag: TelemetryBag = {};

function _getJoyEventType() {
  const path = window.location.pathname === '/' ? 'home' : window.location.pathname.replace(/\//g, '');
  const weddingPages = ['wedding-website', 'registry', 'honeymoon-registry', 'designs'];
  return weddingPages.includes(path) ? JoyEventType.wedding : path === 'baby-registry' ? JoyEventType.babyRegistry : null;
}

function _getTelemetryContext() {
  return { joyEventType: _getJoyEventType(), appName: 'marcom', buildNumber: window?.buildNumber };
}

function _sendTrackEvent(event: string, args: TelemetryBag, options?: SegmentOptions) {
  window.analytics?.track?.(event, { context: _getTelemetryContext(), ...args }, options);
}

function _sendPageEvent(page: string, args?: PageViewArgs, options?: SegmentOptions) {
  window.analytics?.page?.(page, { context: _getTelemetryContext(), ...args }, options);
}

function generateGuid(): string {
  const joyWsid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    const r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
  _sendTrackEvent('JoyWsidGenerated', { category: telemetryBag?.category, label: joyWsid });
  return joyWsid;
}

function _areCookiesEnabled(): boolean {
  let cookieEnabled = false;
  if (typeof navigator !== 'undefined') {
    cookieEnabled = !!navigator.cookieEnabled;
    if (typeof navigator.cookieEnabled === 'undefined' && !cookieEnabled) {
      document.cookie = 'testcookie';
      cookieEnabled = !!(document.cookie.indexOf('testcookie') !== -1);
    }
  }
  return cookieEnabled;
}

function _setCookie(cname: string, cvalue: string, secure: boolean) {
  if (window.localStorage.getItem('cookiesStatus') !== 'declined') {
    const domain = `;domain=${location.host};path=/`;
    let cookie = cname + '=' + cvalue + domain;
    if (secure) {
      cookie = cookie + ';secure';
    }
    document.cookie = cookie;
  }
}

function _getCookie(cname: string) {
  const name = cname + '=';
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') c = c.substring(1);
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}

function _getQueryParameterByName(name: string, url?: string) {
  if (!url) url = window.location.href;
  name = name.replace(/[[\]]/g, '\\$&');
  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

function setCookieByName(name: string, value: string, secure: boolean) {
  let cookie = name + '=' + value;
  if (secure) {
    cookie = cookie + ';secure';
  }
  document.cookie = cookie;
}

function getCookieByName(name: string) {
  if (_areCookiesEnabled() && _getCookie(name)) {
    return _getCookie(name);
  }
}

function deleteAllCookies() {
  if ('undefined' !== typeof document) {
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      document.cookie = ca[i] + '=;expires=' + new Date(0).toUTCString();
    }
  }
}

function getJoyClientInfo(name?: string) {
  let info;
  if (!name) {
    return null;
  }
  if (_areCookiesEnabled() && _getCookie(name)) {
    info = _getCookie(name);
    _sendTrackEvent('ClientInfoRetrieved', { category: telemetryBag?.category, label: capitalize(name) + 'cookie' });
    return info;
  } else if (_getQueryParameterByName(name)) {
    info = _getQueryParameterByName(name);
    _sendTrackEvent('ClientInfoRetrieved', { category: telemetryBag?.category, label: capitalize(name) + 'queryString' });
    return info;
  } else {
    _sendTrackEvent('ClientInfoNotFound', { category: telemetryBag?.category, label: capitalize(name) });
    return null;
  }
}

function initTelemetry() {
  joyWsid = getJoyClientInfo('joyWsid');
  if (!joyWsid) {
    joyWsid = generateGuid();
    _setCookie('joyWsid', joyWsid, true);
    identify({ joyWsid: joyWsid, ui_funnel: 'non_registered' });
  }
  telemetryBag.joyWsid = joyWsid;
}

function identify(traits: IdentifyTraits, options?: SegmentOptions) {
  withWindow(global => {
    global?.analytics?.identify?.(traits, {
      context: {
        // Namespace the context to avoid conflicts with Segment's reserved context keys
        joy: _getTelemetryContext(),
      },
      ...options,
    });
  });
}

function getCategoryOfEvent(pathname: string) {
  if (pathname === '/') {
    return 'home';
  } else return pathname.replace(/\//g, '');
}

function addTelemetryEvent(
  element: React.SyntheticEvent<Element, Event>,
  route: string = 'home',
  event: string = 'WWWNavigate',
  label?: string,
  options?: SegmentOptions
) {
  const category = getCategoryOfEvent(location.pathname);
  telemetryBag.category = 'newmarketing.' + category || 'newmarketing';
  // when there is no route, it's bc it's linking to the home (brand/home) or a closeDownload

  const currentEl = element?.currentTarget as HTMLAnchorElement;
  label = label || currentEl?.id;
  if (currentEl?.text) {
    telemetryBag.text = currentEl?.text.trim();
  } else {
    telemetryBag.text = currentEl?.getAttribute('aria-label') ?? undefined;
  }
  delete telemetryBag.target;
  if (route) {
    telemetryBag.elementId = currentEl?.getAttribute('data-element-id') ?? currentEl?.id;
    telemetryBag.target = route;
    telemetryBag.label = label;
    if (event === 'WWWPartnerRedirect') {
      const target = element.target as HTMLAnchorElement;
      const currentTarget = element.currentTarget as HTMLAnchorElement;
      telemetryBag.label = target?.parentElement?.textContent || undefined;
      telemetryBag.target = currentTarget.hostname;
    }
    if (event === 'goToeWed') {
      const queryParams = new URLSearchParams(currentEl?.href);
      let userId = queryParams.get('userId');
      let eventId = queryParams.get('eventId');
      if (userId) {
        telemetryBag.userId = userId;
      }
      if (eventId) {
        telemetryBag.eventId = eventId;
      }
    }
    _sendTrackEvent(event, telemetryBag, options);
    // create separate Telemetry event to track Create Wedding CTAs on marketing site
    if (route.toLowerCase() === 'createwedding' || route.toLowerCase() === 'createbabyregistry') {
      event = 'StartCreate';
      const optionsOverride = { options, integrations: { [Integrations.Amplitude]: true } };
      _sendTrackEvent(event, telemetryBag, optionsOverride);
    }
  } else {
    telemetryBag.target = currentEl?.id || element;
    _sendTrackEvent(event, telemetryBag, options);
  }
  delete telemetryBag.target;
}

function redirectWithTelemetry(element: React.SyntheticEvent<Element, Event>, event?: string, label?: string, options?: SegmentOptions) {
  const currentEl = element?.currentTarget as HTMLAnchorElement;
  let route = currentEl?.pathname?.replace(/\//g, '') || 'home';
  if (route === 'redirect' && decodeURIComponent(currentEl?.href).indexOf('https://www.ewedinsurance.com') !== -1) {
    addTelemetryEvent(element, route, 'WWWPartnerRedirect', label, options);
    addTelemetryEvent(element, route, 'goToeWed', label, options);
  } else {
    addTelemetryEvent(element, route, event, label, options);
  }
  let openInNewWindow = false,
    finalRoute,
    wsidRoute;
  if (!route) {
    route = '';
  }
  if (route === 'sample') {
    wsidRoute = '/' + 'amyplusdave' + (_areCookiesEnabled() ? '' : '?joyWsid=' + joyWsid);
  } else {
    wsidRoute = '/' + route + (_areCookiesEnabled() ? '' : '?joyWsid=' + joyWsid);
  }

  //external redirect
  if (route.indexOf('http') > -1 || route.indexOf('mailto:') === 0) {
    finalRoute = route;
    openInNewWindow = true;
  } else {
    finalRoute = wsidRoute;
    if (_runningLocally() && (route === 'login' || route === 'signup' || route === 'createWedding' || route === 'me')) {
      finalRoute = 'http://local.withjoy.com:8474' + wsidRoute;
    }
    /*
    // Quick fix for redirecting not working to old eula page.
    if (route === 'EULA') {
      openInNewWindow = true;
    }*/
  }

  if (openInNewWindow) {
    window.open(finalRoute);
    return false;
  }
  return true;
}

function setDestinationHostname() {
  if (_runningLocally()) {
    destHostname = 'local.withjoy.com';
  } else {
    destHostname = window.location.hostname;
  }
}

function _runningLocally() {
  return window.location.hostname.indexOf('joy') === -1;
}

function sendTelemetryPageView(title: string, seoUrl: string, document: Document, options?: SegmentOptions) {
  const { pathname: path } = new URL(seoUrl);
  const { location, referrer } = document;
  const { search, href: url } = location;
  const route = path === '/' ? 'home' : path.replace(/\//g, '');
  addAction('MarcomPageViewSeo', { path, title, joyEventType: _getJoyEventType() });
  _sendPageEvent(`newmarketing.${route}`, { nonInteraction: 1, path, title }, options);
  _sendTrackEvent(
    `newmarketing.${route}`,
    { name: `newmarketing.${route}`, path, referrer, search, title, url: seoUrl, nonInteraction: 1 },
    options
  );
  _sendTrackEvent(
    'Product Viewed',
    {
      product_id: 1,
      price: 0,
      quantity: 1,
      name: `newmarketing.${route}`,
      url,
      nonInteraction: 1,
    },
    options
  );
}

function sendTelemetryEvent(
  eventName: string,
  eventPayload: {
    name?: string;
    label?: string;
    value?: number | string;
    elementId?: string;
    productCatalogItemId?: number | string;
    productCatalogItemPosition?: number;
    extraInfo?: object;
  },
  isNonInteraction?: boolean,
  options?: SegmentOptions
) {
  let nonInteraction = isNonInteraction ? 1 : undefined;
  let route = window.location.pathname === '/' ? 'home' : window.location.pathname.replace(/\//g, '');
  let { name, label, value, elementId, productCatalogItemId, productCatalogItemPosition, extraInfo } = eventPayload;
  _sendTrackEvent(
    eventName,
    {
      route,
      category: `newmarketing.${route}`,
      name: name ? name : label,
      label,
      value,
      elementId,
      productCatalogItemId,
      productCatalogItemPosition,
      nonInteraction,
      extraInfo,
    },
    options
  );
}

function sendTelemetryFilterEvent(
  event: string,
  eventPayload: {
    type: string;
    category: string;
    action: string;
    label: string;
    extraInfo: object;
  },
  options?: SegmentOptions
) {
  let route = window.location.pathname === '/' ? 'home' : window.location.pathname.replace(/\//g, '');
  let { type, category, action, label, extraInfo } = eventPayload;
  _sendTrackEvent(
    event,
    {
      route: route,
      type,
      category,
      action,
      label,
      extraInfo,
      nonInteraction: 1,
    },
    options
  );
}

export const segment = {
  generateGuid,
  initTelemetry,
  identify,
  setDestinationHostname,
  redirectWithTelemetry,
  sendTelemetryPageView,
  sendTelemetryEvent,
  sendTelemetryFilterEvent,
  setCookieByName,
  getCookieByName,
  deleteAllCookies,
};
