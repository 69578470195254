import * as React from 'react';
import { defaultTheme } from '@components/themes/default';
import { Theme } from '@components/themes/types';
import { ThemeProvider } from 'styled-components';

export interface MarcomThemeProviderProps {
  children: JSX.Element;
  theme?: Theme;
}

export interface MarcomStyledComponentsProps {
  theme: Theme;
}

export const MarcomThemeProvider: React.SFC<MarcomThemeProviderProps> = ({ children, theme = defaultTheme }) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};
