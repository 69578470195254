import globalWindow from '@utils/globals';

export const newrelic = globalWindow.newrelic;

export function addAction(actionName: string, value: Record<string, unknown>): void {
  const valueWithPrefixes: Record<string, unknown> = {};

  for (const key in value) {
    valueWithPrefixes[`${actionName}_${key}`] = value[key];
  }

  newrelic?.addPageAction(actionName, valueWithPrefixes);
}

export function addError(error: Error, attributes: Record<string, unknown>): void {
  newrelic?.noticeError(error, attributes);
}
