export const snakeToCamel = (str: string) => {
  const result = str.replace(/([-_][a-zA-Z0-9])/g, (group) => {
    return group.toUpperCase().replace('-', '').replace('_', '');
  });
  return result;
};

export const capitalize = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const snakeToCamelTrimed = (str: string) => {
  return snakeToCamel(str.replace(/ /g, ''));
};
