import { SpaceScale } from './types';

const spaceScalePx = [
  0, //   -> 0  -> 0rem
  4, //   -> 1  -> 0.25rem
  6, //   -> 2  -> 0.375rem
  8, //   -> 3  -> 0.5rem
  12, //  -> 4  -> 0.75rem
  16, //  -> 5  -> 1rem
  24, //  -> 6  -> 1.5rem
  32, //  -> 7  -> 2rem
  40, //  -> 8  -> 2.5rem
  48, //  -> 9  -> 3rem
  64, //  -> 10 -> 4rem
  96, //  -> 11 -> 6rem
  128, // -> 12 -> 8rem
  160, // -> 13 -> 10rem
  192, // -> 14 -> 12rem
  256, // -> 15 -> 16rem
  360, // -> 16 -> 22.5rem
];

export const createSpaceScale = (rootFontSize = 16) => {
  const spaceScale: SpaceScale = spaceScalePx.map((spacePx) => `${spacePx / rootFontSize}rem`);
  return spaceScale;
};
