import { ExperimentClient } from '@amplitude/experiment-js-client';
export enum JoyEventType {
  babyRegistry = 'babyRegistry',
  wedding = 'wedding',
}
interface TelemetryBagContext {
  context?: {
    joyEventType: JoyEventType | null;
  };
}

type TelemetryBagArg = Record<string, string | number | React.SyntheticEvent<Element, Event> | TelemetryBagContext | object | undefined>;
export interface TelemetryBag extends TelemetryBagArg {
  category?: string;
  label?: string;
  joyWsid?: string;
  utm_medium?: string;
  utm_source?: string;
  utm_content?: string;
  utm_term?: string;
  utm_campaign?: string;
  elementId?: string;
  target?: React.SyntheticEvent<Element, Event> | string;
  text?: string;
  userId?: string;
  eventId?: string;
}

export interface PageViewArgs extends TelemetryBagContext {
  name?: string;
  path?: string;
  referrer?: string;
  search?: string;
  title?: string;
  url?: string;
  nonInteraction?: number;
}

export type TrackAgs = Pick<TelemetryBag, 'category' | 'label'>;

export interface PerfumeArgs {
  route: string;
  eventName: string;
  payload: { duration: unknown };
}

export interface CovidCalcSurveySubmissionArgs {
  route: string;
  eventName: string;
  payload: object;
  /*
  payload: {
    countryOfWedding: string;
    selectedMonth: string;
    selectedYear: string;
    numberOfGuests: string;
    canGuestsStaySixFeetApart: string;
    isAirplaneTravelRequired: string;
    isAirplaneTravelRequiredForGuests: string;
    isInternationalTravelRequired: string;
    areImportantGuestsAtRisk: string;
    indoorOutdoorOrBoth: string;
    totalCalculatedScore: string;
    surveyId: string;
  };*/
}

export interface CovidCalcEmailSubmissionArgs {
  route: string;
  eventName: string;
  payload: {
    emailAddress: string;
  };
}

export enum Integrations {
  All = 'All',
  Intercom = 'Intercom',
  Chameleon = 'Chameleon',
  Criteo = 'Criteo',
  Amplitude = 'Actions Amplitude',
  GoogleAnalytics4Web = 'Google Analytics 4 Web',
  SegmentIO = 'Segment.io',
  FacebookPixel = 'Facebook Pixel',
  BingAds = 'Bing Ads',
}

type IntegrationsType = {
  [value in Integrations]?: boolean;
};

// Generic options object with integrations
export interface SegmentOptions {
  integrations?: IntegrationsType;
  anonymousId?: string;
  context?: object;
}

// Note: Lots of different payloads can be passed in here, so args is an object. Not sure the best way to type this
// so that it is extensible to allow for other payloads to come along.
type TrackingFunc = (event: string, args: object, options?: SegmentOptions) => void;

export type IdentifyTraits = Record<string, string | number | undefined>;

type AnalyticsMethods = {
  track?: TrackingFunc;
  identify?: (traits: IdentifyTraits, options?: SegmentOptions) => void;
  trackSubmit?: () => void;
  trackClick?: () => void;
  trackLink?: () => void;
  trackForm?: () => void;
  pageview?: () => void;
  reset?: () => void;
  group?: () => void;
  ready?: (func: () => void) => void;
  alias?: () => void;
  debug?: () => void;
  page?: (page: string, args?: PageViewArgs, options?: SegmentOptions) => void;
  once?: () => void;
  off?: () => void;
  on?: () => void;
  push?: (args: unknown) => void;
  addSourceMiddleware?: () => void;
  addIntegrationMiddleware?: () => void;
  setAnonymousId?: () => void;
  addDestinationMiddleware?: () => void;
};

export type Methods = keyof AnalyticsMethods;

export type Method =
  | 'trackSubmit'
  | 'trackClick'
  | 'trackLink'
  | 'trackForm'
  | 'pageview'
  | 'identify'
  | 'reset'
  | 'group'
  | 'track'
  | 'ready'
  | 'alias'
  | 'debug'
  | 'page'
  | 'once'
  | 'off'
  | 'on'
  | 'addSourceMiddleware'
  | 'addIntegrationMiddleware'
  | 'setAnonymousId'
  | 'addDestinationMiddleware';

interface IdentifyArgs {
  joyWsid: TelemetryBag['joyWsid'];
  ui_funnel: 'non_registered';
}

export interface Analytics extends AnalyticsMethods {
  invoked?: boolean;
  methods?: ReadonlyArray<Method>;
  SNIPPET_VERSION?: string;
  initialize?: boolean;
  load?: (key: string, options: SegmentOptions) => void;
  factory?: (method: Method) => () => Analytics;
  _loadOptions?: SegmentOptions;
}

export interface Polaris {
  closeConsentModal: () => void;
  openConsentModal: () => void;
}

export interface AmplitudeExperimentClient extends ExperimentClient {
  isFirstSdkLoad?: boolean;
}
