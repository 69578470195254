import globalWindow from '@utils/globals';

export interface Storage {
  type: string;
  getItem: (key: string) => string | null | undefined;
  setItem: (key: string, value?: string | null | undefined) => void;
}

const testKey = 'testKey';
const testValue = 'val' + Date.now();

import { MemoryStorage } from './memoryStorage';
import { CookieStorage } from './cookieStorage';
import { LocalStorage } from './localStorage';

const isStorageEnabled = (constructorFn: new () => Storage & { removeItem?: (key: string) => void }) => {
  try {
    const storage = new constructorFn();
    if (!storage) {
      return false;
    }

    storage.setItem(testKey, testValue);
    if (storage.getItem(testKey) !== testValue) {
      return false;
    }

    storage.setItem(testKey, null);
    if (storage.getItem(testKey) !== null) {
      return false;
    }

    if (storage.removeItem) {
      storage.removeItem(testKey);
    }

    return true;
  } catch (err) {
    return false;
  }
};

const areCookiesEnabled = () => {
  if (!globalWindow.navigator || !globalWindow.navigator.cookieEnabled) {
    return false;
  }

  return isStorageEnabled(CookieStorage);
};

const createLocalStorage = (): Storage => {
  if (isStorageEnabled(LocalStorage)) {
    return new LocalStorage();
  } else if (areCookiesEnabled()) {
    return new CookieStorage();
  } else {
    return new MemoryStorage();
  }
};

let storage: Storage;
const getLocalStorage = (): Storage => {
  if (storage) {
    return storage;
  }

  storage = createLocalStorage();
  return getLocalStorage();
};

// TODO: Willwant to reconsider how we initalize this
getLocalStorage();

const createCookiesStorage = (): Storage => {
  if (areCookiesEnabled()) {
    return new CookieStorage();
  } else {
    return new MemoryStorage();
  }
};

let cookiesStorage: Storage;
const getCookiesStorage = (): Storage => {
  if (cookiesStorage) {
    return cookiesStorage;
  }

  cookiesStorage = createCookiesStorage();
  return getCookiesStorage();
};

getCookiesStorage();

export { getLocalStorage, getCookiesStorage };
