// TODO: Remove file once done moving over to theme folder.
const actionPurple9 = 'rgba(250,247,255,1)';
const actionPurple8 = 'rgba(238,235,255,1)';
const actionPurple4 = 'rgba(113,57,191,1)';
const actionPurple3 = 'rgb(95,38,155,1)';
const actionPurple2 = 'rgb(80,32,128,1)';
const actionPurple1 = 'rgba(68,0,102,1)';
const mono7 = 'rgba(255,255, 255, 1)';

export default {
  colors: {
    background: 'rgba(255,255, 255, 1)',
    purple1: 'rgba(251,247,255,1)',
    purple2: 'rgba(241,235,255,1)',
    purple3: 'rgba(229,217,255,1)',
    purple4: 'rgba(95,38,152,1)',
    purple5: 'rgba(51,0,102,1)',
    purple6: 'rgba(32,0,65,1)',
    blue1: 'rgba(247,252,255,1)',
    blue2: 'rgba(235,247,255,1)',
    blue3: 'rgba(211,231,255,1)',
    blue4: 'rgba(122,152,255,1)',
    blue5: 'rgba(79,107,203,1)',
    blue6: 'rgba(0,8,76,1)',
    green1: 'rgba(248,255,247,1)',
    green2: 'rgba(235,255,232,1)',
    green3: 'rgba(201,250,212,1)',
    green4: 'rgba(0,146,137,1)',
    green5: 'rgba(23,111,105,1)',
    green6: 'rgba(0,39,27,1)',
    yellow1: 'rgba(255,251,247,1)',
    yellow2: 'rgba(255,242,227,1)',
    yellow3: 'rgba(255,225,190,1)',
    yellow4: 'rgba(255,168,66,1)',
    yellow5: 'rgba(200,108,0,1)',
    yellow6: 'rgba(65,35,0,1)',
    red1: 'rgba(255,248,247,1)',
    red2: 'rgba(255,236,235,1)',
    red3: 'rgba(255,215,212,1)',
    red4: 'rgba(255,100,89,1)',
    red5: 'rgba(178,54,74,1)',
    red6: 'rgba(102,0,55,1)',
    actionPurple9,
    actionPurple8,
    actionPurple4,
    actionPurple3,
    actionPurple2,
    actionPurple1,
    mono7,
    mono6: 'rgba(251,251,251,1)',
    mono5: 'rgba(246,246,246,1)',
    mono4: 'rgb(235,235,235,1)',
    mono3: 'rgb(214,214,214,1)',
    mono2Fitty: 'rgba(190,190,190,1)',
    mono2: 'rgba(125,125,125,1)',
    mono1: 'rgba(51,51,51,1)',
    linkDefault: actionPurple3,
    linkHover: actionPurple4,
    linkPress: actionPurple2,
    ctaDefault: actionPurple2,
    ctaHover: actionPurple3,
    ctaPress: actionPurple1,
    secondaryCtaDefault: 'transparent',
    secondaryCtaHover: actionPurple9,
    secondaryCtaPress: actionPurple8,
  },
  fonts: {
    h1: 'Austin News Headline Web',
    heading: '"Avenir Next", sans-serif',
  },
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 700,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.125,
  },
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 72],
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
};
