import React, { createContext, ReactNode } from 'react';
import { GraphQLClient } from 'graphql-request';
import { createGraphQLClient } from './createGraphQLClient';

interface GraphQLClientProviderProps {
  endpoint: string;
  children: ReactNode;
}

export const GraphQLClientContext = createContext<GraphQLClient | null>(null);

export const GraphQLClientProvider: React.FC<GraphQLClientProviderProps> = ({ endpoint, children }) => {
  const client = createGraphQLClient(endpoint);
  return <GraphQLClientContext.Provider value={client}>{children}</GraphQLClientContext.Provider>;
};
